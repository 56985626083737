import React, { useEffect, useState } from 'react';
import useGlobal from '../store';
import { string } from 'prop-types';
import { socket } from '../api/socket'
import moment from 'moment';

const HelpCenterScreen = () => {
    const [globalState] = useGlobal();

    const [state, setState] = useState({
        input: { message: '' },
        chatMessages: []
    });
    
    useEffect(() => {
        document.title = `Help Center`;
        socket.on('message', (message => { 
            var chatMessages = state.chatMessages;
            chatMessages.unshift(message);
            setState({ ...state, chatMessages })
            setState({ ...state, input: { message: '' } })
        }))
        return function cleanup() { socket.off('message') }
    }, []);

    function sendMessages(event) {
        event.preventDefault()
        if (event && state.input.message !== '') {
            const form = document.querySelector('#frm1');
            const formInput = form.querySelector('input');
            formInput.focus();

            socket.emit('sendMessage', state.input.message, (error) => {
                if (error) {
                    return alert(error);
                }
                setState({ ...state, input: { message: '' } })
            })
        }
    }

    function renderMessages() {
        return(
            <div className="chat-container">
                {state.chatMessages.map(message => {
                    return (
                        <div className="message" key={message.id}>
                            <span className="timestamp">{moment(message.createdAt).format('h:mm a')}: </span>
                            <span className="message-text">{message.text}</span>
                        </div>
                );
            })}
        </div>
        )
    }
    if (globalState.token !== undefined && globalState.user !== undefined) {
        return (
            <div>
                <div></div>
                <h3>Help Center:</h3>
                <div>Welcome to the Help Center!  Ask something like: Explain the polishing one-way operator ... OR ... Explain BrainWave functionality</div>
                <form id="frm1" onSubmit={sendMessages}>
                    <div className="spacing">
                        <input
                            className="input chat"
                            placeholder='Enter your message....'
                            type={string}
                            name='message'
                            value={state.input.message}
                            onChange={e => {
                                var message = state.input
                                message.message = e.target.value
                                setState({ ...state, input: message })
                            }}
                            autoComplete="off"
                        />
                        <div className="button" name="button" id="button" onClick={sendMessages}>
                            SEND
                    </div>
                    </div>
                </form>
                {renderMessages()}
            </div >
        );
    } else {
        return <div>Loading....</div>
    }
}

export default HelpCenterScreen;