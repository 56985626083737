import React, { useEffect } from 'react';
import '../components/style.css';
import ReactPlayer from 'react-player';

const HomeScreen = () => {
    useEffect(() => {
        document.title = `Home`;
    }, []);

    return (
        <div>
            <div className="openingDisplay">
                <h3>Secure. Smart. Shared. – Empower Your Business with AI.</h3>
            </div>
            <div className="openingDisplay compressed">
                <div>
                    <h5>Welcome to Command-Central</h5>
                </div>
            </div>
  
        </div >
    )
};

export default HomeScreen;

/*

          <div className="openingDisplay compressed">
                <div>
                    <h7>INNOVATING TO IMPROVE LIVES</h7>
                    <h5>Command Robotics Inc.</h5>
                </div>
            </div>
            <div className="openingYoutube">
                <ReactPlayer
                    className="openingYoutube"
                    url="https://youtu.be/9n2LmQtxsOE"
                    playing={true}
                    muted={true}
                />
            </div>
            <div className="openingYoutube">
                <ReactPlayer
                    className="openingYoutube"
                    url="https://youtu.be/u22wplrEZzo"
                    playing={true}
                    muted={true}
                />
            </div>


*/